<template>
  <ion-page>
    <app-header back="home" title="운송사 일별 운반현황" />
    <ion-content :fullscreen="true">
      <template v-if="isConnected">
        <div id="container">
          <ion-grid>
            <ion-row class="ion-justify-content-center">
              <ion-col size-md="1" size-xs="1.5">
                <span>기간</span>
              </ion-col>
              <ion-col size-md="1.5" size-xs="5">
                <ion-input class="calendar" v-model="frMonth" id="frMonth" readonly @click="popChange" ><ion-icon
                    :icon="calendar"></ion-icon></ion-input>
                <ion-popover trigger="frMonth" @didDismiss="onWillDismissPopover">
                  <ion-datetime class="dateitem" id="datetime" display-format="YYYY-MM-DD" min="2021-01-01"
                    v-model="frMonth" presentation="date" :show-default-buttons="true">
                  </ion-datetime>
                </ion-popover>
              </ion-col>
              <ion-col size-md="0.5" size-xs="0.5">
                <span>~</span>
              </ion-col>
              <ion-col size-md="1.5" size-xs="5">
                <ion-input class="calendar" v-model="seMonth" id="seMonth" readonly  @click="popChange"><ion-icon
                    :icon="calendar"></ion-icon></ion-input>
                <ion-popover trigger="seMonth" @didDismiss="onWillDismissPopover">
                  <ion-datetime class="dateitem" id="datetime" display-format="YYYY-MM-DD" min="2021-01-01"
                    v-model="seMonth" presentation="date" :show-default-buttons="true"></ion-datetime>
                </ion-popover>
              </ion-col>
              <ion-col class="ion-margin-vertical" size-md="1" size-xs="3">
                <ion-select class="ion-no-padding" interface="popover" @ionChange="selectFlag($event)" :value="flag.code">
                  <ion-select-option value="%25">전체</ion-select-option>
                  <ion-select-option value="1">시멘트</ion-select-option>
                  <ion-select-option value="2">FA</ion-select-option>
                </ion-select>
              </ion-col>
              <ion-col size-md="3.5" size-xs="9">
                <ion-input id="ddd" class="ion-text-center" readonly placeholder="착지 검색"
                  style="text-align: right; align-items: center; margin: auto;" @click="openModal" @ionInput="cgname($event)"></ion-input>
              </ion-col>
              <ion-col size-md="1.5" size-xs="6">
                <ion-button expand="block" class="excel" @click="exportToExcel" color="success">엑셀변환</ion-button>
              </ion-col>
              <ion-col size-md="1.5" size-xs="6">
                <ion-button expand="block" @click="onReConnect" color="medium">조회</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
          <vue-good-table 
          :columns="columns" 
          :rows="crdms" 
          :row-style-class="rowStyleFunction" 
          :sort-options="{
            enabled: false,
          }" max-height="74vh">
          </vue-good-table>
        </div>
      </template>
      <template v-else>
        <network-error v-on:@onReConnect="onReConnect" />
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonInput,
  IonIcon,
  IonGrid,
  IonPopover,
  IonCol,
  IonRow,
  IonDatetime,
  IonSelectOption,
  IonSelect,
  onIonViewWillEnter,
  IonButton,
  modalController,
  onIonViewWillLeave,
  popoverController
} from '@ionic/vue';

import { defineComponent, reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import { VueGoodTable } from 'vue-good-table-next';
import { UseCrdm } from '@/api/services/crdm';
import { setDateFormat, getDayOfWeek } from '@/utils/dateFormatter';
import DplaceListModal from '@/views/modal/DplaceListModal.vue';
import { calendar } from 'ionicons/icons';
import * as XLSX from 'xlsx';
import { users } from '@/api/services/user';

export default defineComponent({
  components: {
    IonPage,
    IonInput,
    IonPopover,
    IonContent,
    IonGrid,
    IonCol,
    IonRow,
    IonDatetime,
    VueGoodTable,
    IonButton,
    IonIcon,
    IonSelectOption,
    IonSelect,
  },
  setup() {
    const frMonth = ref(setDateFormat(new Date().setDate(1), 'YYYY-MM-DD')); //ref('2023-01-01');
    const seMonth = ref(setDateFormat(Date.now(), 'YYYY-MM-DD')); //ref('2023-02-01'); setDateFormat(Date.now(), 'YYYY-MM-DD')
    const { isConnected, fetchDatas, year, month, crdms } = UseCrdm(); //fetchDuties, fetchGnmus, getGunmGtype, 
    const salenm = reactive({ title: null, });
    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);
    const { saveLog } = users();

    onIonViewWillEnter(async () => {
      saveLog(auth.value.id, '운송사일별운반현황');
    });
    const form = reactive({
      modalState: false,
      popoverState: false
    });
    const flag = reactive({
        code: '%25',
    });

    async function onWillDismiss() {
      form.modalState = false;
    }
    async function onWillDismissPopover() {
      form.popoverState = false;
    }
    async function modalChange() {
      form.modalState = true;
    }
    async function popChange() {
      form.popoverState = true;
    }

    function customd01(row) {
      if (row.sale_qunt) return row.sale_qunt.toLocaleString('ko-KR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }

    function customdc(row) {
      if (row.sale_qunt ) return 'currency';
    }

    const columns = reactive([
      {
        label: '구분',
        field: 'flag_name',
        width: '71px',
      },
      {
        label: '착지',
        field: 'dplace_name',
        width: '120px',
      },
      {
        label: '출하일자',
        field: 'sa_date_nm',
        width: '85px',
      },
      {
        label: '사업장',
        field: 'sicdname',
        width: '75px',
      },
      {
        label: '차량번호',
        field: 'sa_carno',
        width: '85px',
      },
      {
        label: '인도조건',
        field: 'd_name',
        width: '85px',
      },
      {
        label: '품목',
        field: 'itnm',
        width: '100px',
      },
      {
        label: '출하량',
        field: customd01,
        width: '100px',
        tdClass: customdc,
      },
    ]);

    const exportToExcel = () => {
      const data = Object.values(crdms.value).map(row => [row.flag_name, row.dplace_name, row.sa_date_nm, row.sicdname, row.sa_carno, row.d_name, row.itnm, row.sale_qunt,]);
      data.unshift(columns.map(col => col.label));
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "운송사일별운반현황.xlsx");
    }

    onIonViewWillEnter(async () => {
      // const frdutiesPromise = fetchDatas(frMonth.value.substring(0, 10).replaceAll('-', ''), seMonth.value.substring(0, 10).replaceAll('-', ''), salenm.title, '');
      // await frdutiesPromise;
    });

    async function onReConnect() {
      const frdutiesPromise = fetchDatas(flag.code, frMonth.value.substring(0, 10).replaceAll('-', ''), seMonth.value.substring(0, 10).replaceAll('-', ''), salenm.title == null ? '%25' : salenm.title);
      await frdutiesPromise;
    }

    function selectFlag(e) {
      flag.code = e.target.value;
    }

    function cgname(e) {
      if (e == null) {
        document.querySelector('.ion-text-center').value = null;
      } else {
        document.querySelector('.ion-text-center').value = "[" + e + " ]";
      }
    }

    async function openModal() {

      const distinctDplaceCodes = Array.from(new Set(crdms.value.map(crdms => `${crdms.dplace_code} / ${crdms.dplace_name}`)
      .filter((dplaceCode) => !dplaceCode.includes("소계")).filter((dplaceCode) => !dplaceCode.includes("합계")).sort()
      ));

      form.modalState = true;
      const modal =
        await modalController.create({
          component: DplaceListModal,
          componentProps:{
            seachingFlag: 'Carrier',
            distinctDplaceCodes: distinctDplaceCodes
          }
        });
        
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === 'confirm') {
        cgname(data.substring(8));
        salenm.title = data.substring(0, 7);
      } else {
        salenm.title = data;
        cgname(salenm.title);
      }
      form.modalState = false;
    }

    function rowStyleFunction(row) {
      if (row.dplace_name) {
        if (row.dplace_name.substring(0, 2) === '소계') {
          return 'row-subtotal-color';
        } else if (row.dplace_name.substring(0, 2) === '합계') {
          return 'row-total-color';
        }
      }
    }

    onIonViewWillLeave(async () => {
      if (form.modalState == true) {
        await modalController.dismiss()
      } else if (form.popoverState == true) {
        await popoverController.dismiss()
      }
    });

    return {
      isConnected,
      year,
      month,
      frMonth,
      seMonth,
      columns,
      crdms,
      calendar,
      flag,
      getDayOfWeek,
      onReConnect,
      exportToExcel,
      openModal,
      rowStyleFunction,
      form,
      modalChange,
      onWillDismiss,
      onWillDismissPopover,
      popChange,
      selectFlag,
    };
  }
});



</script>
<style scoped>
#container {
  padding: 0.5em;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
}

ion-content {
  text-align: center;
}

ion-col {
  padding: 0;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  color: black;
}

ion-col .calendar {
  padding: 0;
}

ion-item {
  text-align: center;
  height: 40px;
}

ion-grid {
  padding: 0;
}

ion-datetime {
  width: 100%;
}
</style>